<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Шаблоны абонементов', to: '/templates'}, {text: template.name}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="template.name"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="template.name"
                  required
                  label="Название *"
                  :error-messages="fieldError('name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="template.price"
                  required
                  label="Стоимость"
                  :error-messages="fieldError('price')"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="template.lessons_count"
                  required
                  label="Кол-во занятий действия абонемента"
                  :error-messages="fieldError('lessons_count')"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="saveTemplate()"
                  >
                    Сохранить
                  </v-btn>
                  <v-btn
                    v-if="template.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить шаблон?
        </v-card-title>

        <v-card-text>
          Шаблон будет удален без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removeTemplate(template.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import subscriptionApi from '../services/SubscriptionsApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },

    props: ['id'],
    data () {
      return {
        template: {
          id: null,
          name: null,
          price: null,
          lessons_count: [],
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },

        confirmDialog: false,
      }
    },

    mounted () {
      document.title = 'Шаблон абонемента #' + this.id + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.template = {
            id: null,
            name: null,
            peice: null,
            lessons_count: null,
          }
        } else {
          subscriptionApi
            .fetchSubscriptionTemplate(this.id)
            .then(response => {
              this.template = response
              this.loading = false
              document.title = this.template.name + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      saveTemplate () {
        if (this.template.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          subscriptionApi
            .updateSubscriptionTemplate(this.template)
            .then(response => {
              this.template = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          subscriptionApi
            .createSubscriptionTemplate(this.template)
            .then(response => {
              this.template = response
              this.$router.push('/template/' + this.template.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      removeTemplate () {
        this.confirmDialog = false
        this.loading = true
        subscriptionApi
          .removeSubscriptionTemplate(this.template.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
    },
  }
</script>
